import React, { useState, useLayoutEffect } from "react"
import Seo from '../components/seo'
import { graphql } from 'gatsby'
import Search from "../components/search"
import parseSearch from "../helpers/search"
import { MainContainer } from "../components/styles"
import PortfolioCard from "../components/PortfolioCard"
import styled from "styled-components"
import { isMobile } from "react-device-detect"

type Props = {
  data: any
}

interface ProfileEdge {
  node: {
    id: string
    project: {
      title: string
      img: string
      path: string
      links: {
        github: string
        demo: string
      }
    }
  }
}
interface ImgEdge {
  node: {
    name: string
    childImageSharp: object
  }
}
interface ImgHash {
  [key: string]: object;
}

interface IdHash {
  [key: string]: object;
}

const PortfolioCardsContainer = styled.div`
  grid-column-start: 2;
  display: grid;
  
  @media (min-width: 769px) {
    grid-column-gap: 3%;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    grid-template-rows: repeat(${props => props.rows}, ${isMobile ? '40vh' : '60vh'});
  }
  grid-template-rows: repeat(${props => props.rows}, 60vh);

  grid-row-gap: 40px;
`

const PortfolioPage: React.FC<Props> = ({ data }) => {
  const [input, setInput] = useState("");
  const [ids, setIds] = useState<IdHash>({});
  const [rows, setRows] = useState(1)

  const handleInput = (searchString: string) => {
    setInput(searchString)
    searchString.length > 0 ? setIds(parseSearch("portfolio", data.items.edges, searchString)) : null
  }
  const {
    items,
    images: {
      edges
    }
  } = data

  const imgHash = edges.reduce((map: ImgHash, obj: ImgEdge) => {
    map[obj.node.name] = obj.node.childImageSharp
    return map
  }, {})

  useLayoutEffect(() => {
      if (items.edges.length % 3) {
        setRows((items.edges.length / 3 | 0) + 1)
      } else {
        setRows(items.edges.length / 3)
      }
    }
  )

  return <>
    <Seo title="portfolio"/>
    <MainContainer>
      <Search style={{gridColumnStart: 2}} handleInput={handleInput}/>
      <PortfolioCardsContainer rows={rows}>
        {
          items.edges.map((edge: ProfileEdge) => {
          const id = edge.node.id
          const item = edge.node.project
          const {img, title, path, links: {github, demo}} = item
            if (input.length < 1|| ids && ids[id]) {
              return <PortfolioCard image={imgHash[img].fluid} github={github} demo={demo} title={title} path={path}/>
            }
        })}
      </PortfolioCardsContainer>
    </MainContainer>
  </>
}

export default PortfolioPage

export const query = graphql`
    query Portfolio {
       items: allYaml {
            edges {
                node {
                    id
                    project {
                        title
                        img
                        summary
                        path
                        links {
                            github
                            demo
                        }
                    }
                }
            }
        }
       images: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "portfolioImages"}}) {
           edges {
               node {
                   name
                   childImageSharp {
                       fluid(maxWidth: 400) {
                           ...GatsbyImageSharpFluid
                       }
                   }
               }
           }
       }
    }
`
