import React from "react"
import styled from 'styled-components'
import {dark, highlight, LinkButton, LinkButtonContainer, light} from './styles'
import {ProtoCard} from "./card"
import Img from "gatsby-image"
import { Link } from "gatsby"

type Props = {
  title?: string,
  image: object,
  width?: string,
  height?: string,
  dark?: boolean,
  footer?: string,
  github?: string,
  demo?: string,
  path: string
}

const CardTitle = styled.div`
  grid-row-start: 2;
  color: ${highlight};
  margin-top: 10px;
  text-align: center;
  font-size: 1.5rem;
  padding-top: 10px;
  border-top: 1px solid ${highlight};
`

const CardFooter = styled.div`
  grid-row-start: 3;
  text-align: center;
  padding-top: 20px;
  margin-top: 10px;
  width: 100%;
  border-top: 1px solid ${highlight};
`
const PortfolioCardContainer = styled(ProtoCard)`
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: 3fr 1fr;
  width: 100%;
  color: ${dark};
  :active {
    box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 0.25),
    -5px -5px 9px 0 rgba(255, 255, 255, 1);
  }
  @media(prefers-color-scheme: dark) {
    color: ${light};
    background-color: ${dark};
    :active {
      box-shadow: 
      9px 9px 13px 0 rgba(0, 0, 0, 1),
      -5px -5px 9px 0 rgba(255, 255, 255, 0.2);
    }
  }
  cursor: default;
`
const ContentArea = styled.div`
  grid-column-start: 2;
  height: 100%;
`
const ImageContainer = styled.div`
  grid-row-start: 1;
  margin-top: 10px;
  border-radius: 20px;
  height: 90%;
  display: grid;
  width: 70%;
  align-self: center;
  justify-self: center;
  overflow: hidden;
  grid-column-start: 2;
 
  box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 0.25),
    -5px -5px 9px 0 rgba(255, 255, 255, 1);
    :active {
    box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
      inset -8px -8px 12px 0 rgba(255, 255, 255, 0.45);
    }
  @media (prefers-color-scheme: dark) {
    box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 1),
    -5px -5px 9px 0 rgba(255, 255, 255, 0.2);
    color: ${light};
    :active {
    box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2),
      inset -8px -8px 12px 0 rgba(255, 255, 255, 0.1);
    }
`

const PortfolioCard: React.FC<Props>= ({title,  image, github, demo, path}) => <PortfolioCardContainer>
  <ImageContainer>
    <Link to={path} style={{ textDecoration: "none", color: "black" }}>
      <Img fluid={image}/>
    </Link>
  </ImageContainer>
  <ContentArea>
    <CardTitle>
      <Link to={path} style={{ textDecoration: "none", color: highlight }}>
        {title}
      </Link>
    </CardTitle>
    <CardFooter>
      <LinkButtonContainer>
        <LinkButton target="_blank" href={github}>
          github
        </LinkButton>
        <LinkButton target="_blank" href={demo}>
          demo
        </LinkButton>
      </LinkButtonContainer>
    </CardFooter>
  </ContentArea>
</PortfolioCardContainer>




export default PortfolioCard
